import React from 'react'
import ShoppingCard from '../shoppinCard/ShoppingCard'

const ShoppingList = ({data}) => {
    console.log("les data du shopping", data)
  return (
    <>   
    {
      data?.map((item,index) =>(
        <ShoppingCard item={item} key={index} />
      ))
    }
    </>
  )
}

export default ShoppingList
