import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ProductsItems: []
}

const productSlice = createSlice({
  name: 'produit',
  initialState,
  reducers: {
    addItem:(state, action)=>{
        const newItem = action.payload;
        const existingItem = state.ProductsItems.find((item)=>item.id === newItem.id)
        console.log("le newItem",newItem)
        
        // state.totalQuantity++
        if(!existingItem){
            state.ProductsItems.push(newItem)
        } else return

    },

  }
});

export const ProductActions = productSlice.actions

export default productSlice.reducer