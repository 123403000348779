import React, { useRef, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { auth } from "../../firebase.config";
import { signOut } from "firebase/auth";
import UseAuthe from "../../custom-hooks/UseAuthe";
import { useTranslation } from "react-i18next";

import "./header.css";
import { Token } from "../UI/utils/utilfunction";
import Lang from "./lang/Lang"
import logo from "../../assets/images/logo-marsha.png";
import userIcon from "../../assets/images/user-icon.png";



const Header = () => {
	const { t } = useTranslation()

	const nav_links = [
		{
			path: "Home",
			display: t("link1"),
		},
		{
			path: "Shop",
			display: t("link2"),
		},
		{
			path: "Services",
			display: t("link3"),
		},
		{
			path: "Cart",
			display: t("link4"),
		},
		{
			path: "Contact",
			display: t("link5"),
		},
	];

	const { currentUser } = UseAuthe();
	const totalQuantity = useSelector((state) => state.cart.totalQuantity);
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	// const [userAccess, setUserAccess] = (currentUser)

	const headerRef = useRef(null);
	const menuRef = useRef(null);
	const navigate = useNavigate();
	const profileRef = useRef(null);

	//function to add shadow at navbar
	const stickyHeaderFunc = () => {
		window.addEventListener("scroll", () => {
			if (
				document.body.scrollTop > 80 ||
				document.documentElement.scrollTop > 80
			) {
				headerRef.current.classList.add("sticky_header");
			} else {
				headerRef.current?.classList.remove("sticky_header");
			}
		});
	};

	useEffect(() => {
		stickyHeaderFunc();

		return () => {
			window.removeEventListener("scroll", stickyHeaderFunc);
		};

	}, []);

	const menuToggle = () => menuRef.current.classList.toggle("active_menu");
	const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

	//fction to show profile
	const actionProfile = () => {
		profileRef.current.classList.toggle("show_profile");
	};

	//fction to logout
	const Logout = () => {
		signOut(auth);
		navigate("/");
	};
	console.log("temps de connexion",currentUser)

	return (
		<header className="header" ref={headerRef}>
			<Container>
				<Row>
					<div className="nav_wrapper">
						<div className="logo">
						<Link to="/"><img className="log" src={logo} alt="logo" /></Link>
							{/* <div>	<h6>Marsha{t("title")}</h6><p>since 2022</p></div> */}
						</div>

						<div className="navigation" ref={menuRef} onClick={menuToggle}>
							<ul className="menu">
								{nav_links.map((item, index) => (
									<li className="nav_item" key={index}>
										<NavLink
											to={item.path}
											className={(navClass) =>
												navClass.isActive ? "nav_active" : ""
											}
										>
											{item.display}
										</NavLink>
									</li>
								))}
								<li className="nav_item" >
									<Lang />
								</li>
							</ul>
						</div>
						<div className="nav_icons">
							<span className="fav_icon">
								<i class="ri-heart-line"></i>
								<span className="badge">1</span>
							</span>
							<span
								id="cart_icon"
								className="cart_icon"
								onClick={() => navigate("/cart")}
							>
								<i
									class="ri-shopping-bag-line"
									onClick={() => navigate("/cart")}
								></i>
								<span className="badge">{totalQuantity}</span>
							</span>
							<span>
								<div className="profile">
									<motion.img
										onClick={actionProfile}
										whileTap={{ scale: 1.2 }}
										src={currentUser ? currentUser.photoURL : userIcon}
										alt="user"
									/>

									<div
										className="profile_actions"
										ref={profileRef}
										onClick={actionProfile}
									>
										{currentUser ? (
											<>
												{/* <Link to="./admin"><div>Dashboard</div></Link> */}
												<div onClick={Logout} to="/" >Logout</div>
											</>
										) : (
											<div className="d-flex mobile_list  align-items-center justify-content-center flex-column">
												<Link to="/login/1">Signup</Link>
												<Link to="/login/0">Login</Link>
												<Link onClick={Logout} to="/" >Logout</Link>
												{/* <Link to="/admin">Dashboard</Link> */}
											</div>
										)}
									</div>
								</div>
							</span>
							<div className="mobile_menu" id="mobile_menu">
								<span onClick={menuToggle}>
									<i class="ri-menu-line"></i>
								</span>
							</div>
						</div>
					</div>
					<div>
						<Tooltip
							placement="top"
							isOpen={tooltipOpen}
							autohide={false}
							target="cart_icon"
							toggle={toggleTooltip}
						>
							Pannier
						</Tooltip>
					</div>
				</Row>
			</Container>
		</header>
	);
};

export default Header;
