import React, { useState, useEffect, useRef } from "react";
import {
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { tailspin } from "ldrs";
import products from "../../assets/data/products";
import { factureActions } from "../../redux/slices/factureSlice";
import { getData, postData2 } from "../../components/UI/utils/utilfunction";
import items from "../../assets/data/items";
import "./vendre.css";
import diffuseur from "../../assets/image/diffuser-08.jpg";
import logo from "../../assets/images/eco-logo.png";
import tof from "../../assets/images/double-sofa-01.png";

const Vendre = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showTable, setShowtable] = useState(false);
	const [FileredList, setFileredList] = useState(false);
	const [nombreState, setNombreState] = useState();
	// const [prixState, setPrixState] = useState();
	const [valueState, setvalueState] = useState();
	const [showbtn, setShowbtn] = useState(false);
	const [showbtn2, setShowbtn2] = useState(false);
	const [Article, setArticle] = useState({
		id: 1,
		status: "published",
		user_created: "3dc7e48f-cace-4b33-b3e6-6f782c2763b7",
		prix: 13000,
		titre:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
		note: 4.5,
		quantite: 100,
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
		nom: "pitbul sac",
		images: [
			{
				directus_files_id: "77cbfe2a-c34f-40b9-b5c6-c9dfb03ef3ef",
			},
			{
				directus_files_id: "f58509c7-7a9e-417d-b153-b77b6224c8e4",
			},
			{
				directus_files_id: "47c08cfe-fd27-47e5-b9b3-c8e9fcb212af",
			},
			{
				directus_files_id: "f9047a8d-3c66-4c7e-b520-19c0312a95c3",
			},
		],
		categorie: {
			Nom: "CHAIR",
		},
	});

	tailspin.register();
	const navigate = useNavigate();
	// console.log("object items",items.data)

	// funtion to fetchdata
	const fetchdata = (value) => {
		fetch(value)
			.then(resp => resp.json())
			.then(data => {
				// const results = data.filter( val => {
				//     return (
				//         value &&
				//         val &&
				//         val.productName &&
				//         val.imgUrl1 &&
				//         val.avgRating &&
				//         val.productName.toLowerCase().includes(value)
				//     )
				// })
				setFilterData(data)
				console.log(data, "les data du serveur")
			})
	}

	//filered products
	const handleChange = (value) => {
		setFileredList(true);
		// fetchData(value);
		let searchedProduct = filterData.filter((item) =>
			item.nom.toLowerCase().includes(value.toLowerCase())
		);
		setData(searchedProduct);
		if (value === "" || value === " ") {
			setData([]);
		}
	};

	const handleArticle = (article, e) => {
		console.log("item envoye", article);

		if (article) {
			setLoading(true);
			document.getElementById("searchform").reset();
			setFileredList(false);

			setTimeout(() => {
				// setArticle({...Article, id: article.id, status: article.status, nom: article.nom, categorie: article.categorie, description: article.description, images: article.image, note: article.note, prix: article.prix, quantite: article.quantite, titre: article.titre, user_created: article.user_created})
				setArticle(article);
				setLoading(false);
				setShowtable(true);
			}, 500);
		}
		// setTimeout((article                                                                      ) => {
		//   setArticle(article)
		//   setLoading(false)
		//   setShowtable(true)
		// }, 500);
	};
	console.log("article choisi", Article);

	const handlePrix = (value) => {
		if (0 <= value && (value <= Article.quantite)) {
			setNombreState(Number(value));
			setvalueState(Article.prix * value);
			setShowbtn(true);
			// setPrixState(Article.prix)
		} else if (value < 0 || Article.quantite < value) {
			setNombreState(0);
			setvalueState(0);
			setShowbtn(false);
		}
	};

	function performSignIn(url) {
		let headers = new Headers();

		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');
		headers.append('Authorization', 'Basic ');
		headers.append('Origin', 'http://localhost:3000');

		fetch(url, {
			mode: 'no-cors',
			credentials: 'include',
			method: 'GET',
			headers: headers
		})
			.then(response => response.json())
			.then(data => console.log("le retour des infos", data))
			.catch(error => console.log('Authorization failed: ' + error.message));
	}

	useEffect(() => {
		// setFilterData(items);


		getData(
			"https://api.marsha-boutique.com/items/Articles?fields=*,categorie.Nom, images.directus_files_id"
		).then((item) => setFilterData(item.data));
	}, []);

	console.log("article", Article);
	// console.log("les data items", items);
	console.log("les data filteredata", filterData);

	useEffect(() => {
		setNombreState();
		setvalueState();
		// setPrixState()
		setShowbtn(false);
	}, [loading]);

	// console.log("le value state",valueState)

	const Dispatch = useDispatch();
	const factureItems = useSelector((state) => state.facture.factureItems);
	const totalAmount = useSelector((state) => state.facture.totalAmount);
	const totalQuantite = useSelector((state) => state.facture.totalquantite);

	const addToCart = () => {
		if (valueState <= 0) {
			toast.error("veuillez ajoute un prix à article!");
			return;
		} else {
			Dispatch(
				factureActions.addItem({
					id: Article.id,
					nom: Article.nom,
					prix: Article.prix,
					images: Article.images,
					quantite: nombreState,
					totalPrix: nombreState,
				})
			);

			toast.success("produit ajouté avec success");
			setShowbtn(false);
			setShowbtn2(true);

		}
	};

	const handlevalue = (val) => {
		setNombreState(Number(val));
	};

	// console.log("expli article", Article);

	return (
		<div className="vendre position-absolute ">
			<div className="vendre_top pt-5">
				<Container className="d-flex justify-content-around  align-items-center ">
					<div className="lo">
						<img src={logo} alt="logo" />
						<div>
							<Link to="#">
								<h2>
									<span className="tec">Marsha</span>
									{t("title")}
								</h2>
							</Link>
							{/* <p>since 2022</p> */}
						</div>
					</div>
					<div className="search">
						<form action="#" id="searchform">
							<input
								type="search"
								placeholder="Trouver un Produit..."
								onChange={(e) => handleChange(e.target.value)}
								name=""
								id="search"
							/>
							<span className="btn_search">Rechercher</span>
						</form>
						{FileredList ? (
							<div className="result_liste">
								{data.map((item, index) => (
									<div
										className="liste_search"
										key={index}
										onClick={() => handleArticle(item)}
									>
										<img
											className="img_filtrer"
											src={item.images == 0 ? "" : `https://api.marsha-boutique.com/assets/${item.images[0].directus_files_id}`}
											alt={item.nom}
										/>
										<div className="list_detail">
											<h4>{item.nom}</h4>
											<h6>{item.prix} Xaf</h6>
										</div>
									</div>
								))}
							</div>
						) : (
							""
						)}
					</div>
					<div className="pointer" onClick={() => navigate("/facture")}>
						<Link
							id="cart_icone"
							className="cart_icon"
							onClick={() => navigate("/facture")}
						>
							<i
								class="ri-shopping-bag-line"
								onClick={() => navigate("/facture")}
							></i>
							<span className="badge">{totalQuantite}</span>
						</Link>
					</div>
				</Container>
			</div>
			<div className="vendre_body">
				{showTable ? (
					loading ? (
						<div className="loader d-flex justify-content-center align-items-center ">
							{" "}
							<l-tailspin
								size="40"
								stroke="5"
								speed="0.9"
								color="black"
							></l-tailspin>
						</div>
					) : (
						<Container>
							<div>
								<legend>Recapitulatif de Vente</legend>

								<div className="contain d-flex">
									<Col md="5" className="colon">
										<Form className="billing_form">
											<FormGroup className="form_group">
												<Label>Quantite Produit *</Label>
												<input
													onChange={(e) => handlePrix(e.target.value)}
													value={nombreState || 0}
													className="prix"
													type="text"
													placeholder="Nombre..."
												/>
											</FormGroup>

											<FormGroup className="form_group">
												<Label>Prix Unitaire *</Label>
												<input
													className="prix"
													type="nomber"
													value={Article.prix}
												/>
											</FormGroup>

											<FormGroup className="form_group">
												<Label>Prix Total *</Label>
												<input
													value={valueState || 0}
													className="prix"
													type="text"
													placeholder="Prix..."
												/>
											</FormGroup>
										</Form>
									</Col>

									<Col md="5" className="colon_left d-flex ">
										<div className="detail_top">
											<h6 className="my-2 text-center text-light fw-bold">
												Information du Produit
											</h6>
											<div className="detail ">
												<div className="cadre_top">
													<p className="text-light ">Nom {Article.nom}</p>
													<p className="text-light ">
														Cat: {Article.categorie.Nom}
													</p>
													<p className="text-light ">
														Prix: {Article.prix} XAF
													</p>
													<p className="text-light desc">
														{Article.description}
													</p>
												</div>
												<div className="cadre my-3">
													<img
														className="tof_cadre"
														src={Article.images == 0 ? "" : `https://api.marsha-boutique.com/assets/${Article.images[0].directus_files_id}`}
														alt="tof produit"
													/>
													<br />
												</div>
												<div className="text-white stock">
													stock: {Article.quantite}
												</div>
											</div>
										</div>
									</Col>
								</div>
								<div className="buton_group">
									{/* <FormGroup className='form_group'>
                                <input className='py-3 py-md-2 mt-3' type="number" name="" onChange={e => handlePrix(e.target.value)} id="" placeholder='Nombre de produits' />
                                </FormGroup> */}
									<button
										className={showbtn2 ? "bute" : "disabled bute"}
										onClick={() => navigate("/facture")}
									>
										Facturer
									</button>
									<button
										onClick={() => setShowtable(false)}
										className="bg-black text-light but"
									>
										Annuler
									</button>
									<button
										className={showbtn ? "bute" : "disabled bute"}
										onClick={addToCart}
									>
										Vendre le produit
									</button>
								</div>
							</div>
						</Container>
					)
				) : (
					<div>
						{loading ? (
							<div className="loader d-flex justify-content-center align-items-center ">
								{" "}
								<l-tailspin
									size="40"
									stroke="5"
									speed="0.9"
									color="black"
								></l-tailspin>
							</div>
						) : (
							<h2 className="mt-5 text-center ">
								{" "}
								Veuillez Retrouver un Produit Dans la barre de recherche
							</h2>
						)}
					</div>
				)}
			</div>

			{
				showTable ? "" : <div className="vendre_footer"></div>
			}
		</div>
	);
};

export default Vendre;