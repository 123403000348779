const serviceData = [
  {
    icon: "ri-truck-line",
    title: "Livraison Facile",
    subtitle: "Vous êtes livrer dans votre maison.",
    bg: "#EC8BFF",
  },
  {
    icon: "ri-refresh-line",
    title: "Facilité de retour",
    subtitle: "Retourner un produit à vos frais.",
    bg: "#FDDD86",
  },
  {
    icon: "ri-secure-payment-line",
    title: "Paiement sécurisé",
    subtitle: "securité solide.",
    bg: "#fb78dc",
  },
  {
    icon: "ri-exchange-dollar-line",
    title: " Qualitée Garantie",
    subtitle: "Produits de hautes qualités.",
    bg: "#f9db51",
  },
];

export default serviceData;
