import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Layout from "./components/Layout/Layout";
// import Layout from "./components/layout/Layout";

import "./App.css";

function App() {

  return (
    <>
      <Layout />
    </>
  );
 
}

export default App;
