import { Routes, Route, Navigate} from 'react-router-dom'
import React from 'react'

import Home from '../pages/home/Home'
import Shop from '../pages/shop/Shop'
import Cart from '../pages/cart/Cart'
import Contact from '../pages/contact/Contact'
import ProductDetails from '../pages/productDetail/ProductDetails'
import Checkout from '../pages/checkout/Checkout'
import Login from '../pages/login/Login'
import Signup from '../pages/signup/Signup'
import ProtectedRoutes from './ProtectedRoutes'
import Services from '../pages/services/Services'
import Vendre from '../pages/vendre/Vendre'
import AddProducts from '../admin/dashboard/AddProducts'
import AllProducts from '../admin/dashboard/AllProducts'
// import Dashboard from '../admin/dashboard/Dashboard'
import Admin from '../admin/Admin'
import Facture from '../pages/facture/Facture'

const Routers = () => {
  return (
    <Routes>
        <Route exact path="/" element={<Navigate to="home" />} />
        <Route exact path="home" element={<Home />} />
        <Route exact path="shop" element={<Shop />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="cart" element={<Cart />} />
        <Route exact path="productDetails/:id" element={<ProductDetails/>} />
        <Route exact path="login/:id" element={<Login />} />
        <Route exact path="signup" element={<Signup />} />
        <Route exact path="services" element={<Services />} />

        <Route path='/*' element={<ProtectedRoutes />} >
          <Route exact path={"checkout"} element={<Checkout/>} />
          <Route exact path={"dashboard/all-products"} element={<AllProducts/>} />
          <Route exact path={"dashboard/add-product"} element={<AddProducts/>} />
          <Route exact path={"marsha-admin"} element={<Admin/>} />
          <Route exact path="vendre" element={<Vendre />} />
          <Route exact path="facture" element={<Facture />} />
        </Route>

        <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}

export default Routers