import React from 'react'
import { Container,Col,Row } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'

import "./admin.css"
import image from "../assets/images/marsha.png"
import marsha from "../assets/images/logo-marsha.png"

const Admin = () => {
  const navigate = useNavigate()
  return (
    <div className="admin">
      <div className="admin_left">
        <div className="docke">
          <span className="docke_image">
            <img src={marsha} className="logo_admin" alt="logo marsha" />
          </span>
          <div className="ms-3">
            <h3 className="text-light fs-6 fw-bold "> Marsha Boutique</h3>
            <p className="mt-1">Marsha Management Platform</p>
          </div>
        </div>
        <div className='mt-5'>
          <h1 className='mt-5 py-3 text-light'>Se Connecter</h1>
          <p className='text-light'>
            Cliquer sur Continuer pour la vente depuis la Boutique. <br />
          </p>
          <p className='mt-2 text-light'>
          Pour acceder à l'administration
            cliquer sur Administrer
          </p>
        </div>
        <div className="btn_core d-flex mt-5">
          <Link to="https://api.marsha-boutique.com/admin/login"><button className='py-3 px-4 px-sm-4  text-black fw-bold rounded-3 me-3'>Administrer</button></Link>
          <button className='py-3 px-4 px-sm-4 text-black fw-bold rounded-3 '  onClick={()=>navigate("/vendre")}>Continuer</button>
        </div>
      </div>
      <div className="admin_right">
        <img src={image} className="banner_admin" alt="logo marsha boutique" />
      </div>
    </div>
  );
}

export default Admin