import React,{useEffect} from 'react'
import { Container, Row, Col, Form, FormGroup } from 'reactstrap'
import Helmet from '../../components/helmet/Helmet'
import CommonSection from '../../components/UI/commonSection/CommonSection'
import { UseSelector, useSelector } from 'react-redux'

import './checkout.css'
const Checkout = () => { 

  const totalQty = useSelector(state => state.cart.totalQuantity)
  const totalAmount = useSelector(state => state.cart.totalAmount)

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Helmet title="Checkout">
      <CommonSection title="checkout" />
      <section>
        <Container>
          <Row>
            <Col lg="8">
              <h6>Informations de facturation</h6>
              <Form className="billing_form">
                <FormGroup className="form_group">
                  <input type="text" placeholder="Entrez votre nom" />
                </FormGroup>

                <FormGroup className="form_group">
                  <input type="email" placeholder="Entrez votre Email" />
                </FormGroup>

                <FormGroup className="form_group">
                  <input type="number" placeholder="Numéro de téléphone" />
                </FormGroup>

                <FormGroup className="form_group">
                  <input type="text" placeholder="Adresse Postale" />
                </FormGroup>

                <FormGroup className="form_group">
                  <input type="text" placeholder="Ville" />
                </FormGroup>

                <FormGroup className="form_group">
                  <input type="text" placeholder="Pays" />
                </FormGroup>
              </Form>
            </Col>
            <Col lg="4">
              <div className="checkout_cart">
                <h6>
                  Total Qte: <span>{totalQty} Articles </span>
                </h6>
                <h6>
                Total général: <span>Xaf {totalAmount } </span>
                </h6>
                <h6>
                  <span>
                    Transport: <br />
                    Livraison Incluse
                  </span>
                  <span>Xaf 1000 </span>
                </h6>

                <h4>
                  Total Coast: <span>Xaf {totalAmount + 1000}</span>{" "}
                </h4>
                <button className="btn_detail auth_btn">Passez une commande</button>
              </div>
              
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
}

export default Checkout