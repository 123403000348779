import React from "react";
import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,A11y, EffectFade, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";

import heroImg from "../../assets/images/hero-img.png";
import heroImg2 from "../../assets/images/hero-img2.png";
import heroImg3 from "../../assets/images/hero-img3.png";

// Import Swiper styles
import "swiper/css";
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay'
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./banner.css";


const Banner = () => {
  const {t} = useTranslation()  
  const year = new Date().getFullYear();

    return (
      <>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          loop={true}
          autoplay={{ delay: 7000 }}
          modules={[Pagination, Autoplay, A11y]}
          // slidesPerView={0}
          className="mySwiper"
        >
          <SwiperSlide className="mt-5">
            <div className="hero_div">
              <Container>
                <Row className="role">
                  <Col lg="6" md="6">
                    <div className="hero_content">
                      <p className="hero_subtitle">
                        {t("banner1")} {year}
                      </p>
                      <h2>
                        Achetter les Produits de Beautés à la mode qui vous
                        conviennent
                      </h2>
                      <p>
                        Transformer vos interieurs et créer une ambiance unique avec nos senteurs et purificateurs d'air
                      </p>

                      <Link to="/shop">
                        <motion.button
                          whileTap={{ scale: 1.2 }}
                          whileHover={{ translateY: "-0.4rem" }}
                          className="buy_btn"
                        >
                          {t("banner4")}
                        </motion.button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="getar">
                    <div className="hero_img">
                      <img
                        className="hero_img-content2"
                        src={heroImg2}
                        alt="heroImage"
                      />
                    </div>
                    <div class="hero-ripple">
                      <i class="ripple"></i>
                      <i class="ripple"></i>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>

          <SwiperSlide className="mt-5">
            <div className="hero_div pt-3 mb-1 ">
              <Container>
                <Row className="role">
                  <Col lg="6" md="6">
                    <div className="hero_content">
                      <p className="hero_subtitle">
                        {t("banner1")} {year}
                      </p>
                      <h2>{t("banner2")}</h2>
                      <p>{t("banner3")}</p>
                      <Link to="/shop">
                        <motion.button
                          whileTap={{ scale: 1.2 }}
                          whileHover={{ translateY: "-0.4rem" }}
                          className="buy_btn"
                        >
                          {t("banner4")}
                        </motion.button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <div className="hero_img">
                      <img
                        className="hero_img-content"
                        src={heroImg}
                        alt="heroImage"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>

          <SwiperSlide className="mt-5">
            <div className="hero_div">
              <Container>
                <Row className="role">
                  <Col lg="6" md="6">
                    <div className="hero_content">
                      <p className="hero_subtitle">
                        {t("banner1")} {year}
                      </p>
                      <h2>
                        Achetter les Produits de Beautés à la mode qui vous
                        conviennent
                      </h2>
                      <p>
                        Redécouvrer le plaisir des senteurs dans une ambiance familliale avec nos senteurs aromatiques uniques
                      </p>

                      <Link to="/shop">
                        <motion.button
                          whileTap={{ scale: 1.2 }}
                          whileHover={{ translateY: "-0.4rem" }}
                          className="buy_btn"
                        >
                          {t("banner4")}
                        </motion.button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="getar">
                    <div className="hero_img">
                      <img
                        className="hero_img-content2"
                        src={heroImg3}
                        alt="heroImage"
                      />
                    </div>
                    <div class="hero-ripple">
                      <i class="ripple"></i>
                      <i class="ripple"></i>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>

          {/*2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide> */}
          {/* <SwiperSlide>Slide 5</SwiperSlide>
          <SwiperSlide>Slide 6</SwiperSlide>
          <SwiperSlide>Slide 7</SwiperSlide>
          <SwiperSlide>Slide 8</SwiperSlide>
          <SwiperSlide>Slide 9</SwiperSlide> */}
        </Swiper>
      </>
    );
  };
  
  export default Banner;
 