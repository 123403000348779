const getData = async (url) =>{
    try {
        const resp = await fetch(url);
        const data = await resp.json()
        console.log("les daatas ",data)
      return data
    } catch (error) {
        console.log("Erreur : apres fetch des data");
    }
}

// Exemple d'implémentation pour une requête POST
const postData = async (url = "", donnees = {}) => {
     // Les options par défaut sont indiquées par *
     const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(donnees), // le type utilisé pour le corps doit correspondre à l'en-tête "Content-Type"
      });
      return response.json(); // transforme la réponse JSON reçue en objet JavaScript natif
}

const postData2 = async (url,donnees) => {
    try {
      const reponse = await fetch(url, {
        method: "POST", // ou 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(donnees),
      });
  
      const resultat = await reponse.json();
      console.log("Réussite :", resultat);
    } catch (erreur) {
      console.log("Erreur :");
    }
  }

  
  
const Token = {
  displayName: "stallone",
  mail: "yapnayoupi@gmail.com",
}

export {postData, postData2, getData, Token}