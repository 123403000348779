import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBiUrzKuLh5XWVczxHXT38sdVhdW3VZf34",
  authDomain: "marsha-2023.firebaseapp.com",
  projectId: "marsha-2023",
  storageBucket: "marsha-2023.appspot.com",
  messagingSenderId: "1023876150415",
  appId: "1:1023876150415:web:4f35bb20ca41708eb82cfa",
  measurementId: "G-3X02FFDCG3"
};

// Initialize Firebase
const App = initializeApp(firebaseConfig);
const analytics = getAnalytics(App);

export const auth =  getAuth(App)
export default App
export const db = getFirestore(App)
export const storage = getStorage(App)