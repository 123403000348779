import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { motion } from 'framer-motion'

import Helmet from '../../components/helmet/Helmet'
import heroImg from '../../assets/images/hero-img.png'
import counterImg from '../../assets/images/counter-timer-img.png'
import { Dispatch } from '@reduxjs/toolkit'
import { ProductActions } from '../../redux/slices/productSlice'
import serviceData from '../../assets/data/serviceData'
import { tailspin } from "ldrs";

import './home.css'
import Services from '../services/Services'
import ProductsList from '../../components/UI/producList/ProductList'
import Clock from '../../components/UI/clock/Clock'
import Banner from '../../components/banner/Banner'
import Hero from '../../components/hero/Hero'
// import { getData } from '../../components/UI/utils/utilfunction's
import { useDispatch } from 'react-redux'

const Home = () => {

	const [trendingProducts, setTrendingProducts] = useState([])
	const [bestSalesProducts, setBestSalesProducts] = useState([])
	const [mobileProducts, setMobileProducts] = useState([])
	const [wirelessProducts, setWirelessProducts] = useState([])
	const [popularProducts, setPopularProducts] = useState([])
	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const url = "https://api.marsha-boutique.com/items/Articles?fields=*,categorie.Nom,images.directus_files_id"
	tailspin.register();

	const navigate = useNavigate()


	//function to control scrool
	const stickyHeaderFunc = () => {
		let rootElement = document.querySelector("#home");
		let toUp = document.querySelector(".to-up");
		let blub = document.querySelector(".blub");


		window.addEventListener("scroll", () => {
			if (
				document.body.scrollTop > 600 ||
				document.documentElement.scrollTop > 600
			) {
				toUp.classList.add("appear");
				blub.classList.add("appear");
			} else {
				toUp.classList.remove("appear");
				blub.classList.remove("appear");
			}
		});
	}

	//function to reuest data products
	const getData = async (url) =>{
		try {
			const resp = await fetch(url);
			const data = await resp.json()
			console.log("les daatas ",data)
		  return data
		} catch (error) {
			console.log("Erreur : apres fetch des data");
		}
	}


	useEffect(() => {
		stickyHeaderFunc()

		getData(
			url
		).then((item) => {
			setProducts(item.data)
			setLoading(false)
			console.log("item", item)
			AddProducts(item)
			const filteredTrendingProducts = item.data.filter(
				element => element.categorie.Nom == "parfum"
			)
			const filteredBestSalesProducts = item.data.filter(
				element => element.categorie.Nom == "diffuseur"
			)
			const filteredMobileProducts = item.data.filter(
				element => element.categorie.Nom == "freshner"
			)
			const filteredWirelessProducts = item.data.filter(
				element => element.categorie.Nom == "bougie"
			)
			const filteredPopularProducts = item.data.filter(
				element => element.categorie.Nom == "vaporisateur"
			)

			setTrendingProducts(filteredTrendingProducts)
			setBestSalesProducts(filteredBestSalesProducts)
			setMobileProducts(filteredMobileProducts)
			setWirelessProducts(filteredWirelessProducts)
			setPopularProducts(filteredPopularProducts)
			// console.log("les produits de la database", products)
			// console.log("les produits de la database", filteredTrendingProducts)
			// console.log("les produits de la wireless", filteredWirelessProducts)
			// console.log("les produits de la popular", filteredPopularProducts)
		});

		return () => { };
	}, []);

	const AddProducts = (produits)=> {
		dispatch(
			ProductActions.addItem(produits)
		)
	}

	localStorage.setItem("user", products)

	console.log("les produits", products)
	console.log("les produits sale", bestSalesProducts)
	console.log("trendin produit", trendingProducts)

	return (
		<Helmet title={"Home"} id="home">

			<Banner />
			<Container className="mt-xs-3 pt-xs-1 my-5 pt-5">
				<Row className="mt-lg-3">
					<Col lg="12" className="text-center mb-5">
						<h2 className="section_title">Le meilleur du service</h2>
					</Col>
					{
						serviceData.map((item, index) => (
							<Col lg="3" md="4" key={index}>
								<div
									className="service_item"
									style={{ background: `${item.bg}` }}
								>
									<span>
										<i className={item.icon}></i>
									</span>
									<div>
										<h3 className='home_service_title'>{item.title}</h3>
										<p className='body_service_title'>{item.subtitle}</p>
									</div>
								</div>
							</Col>
						))
					}
				</Row>
			</Container>

			<div className="to-up">
				<div className="blub ">
					{" "}
					<a onClick={() => window.scrollTo(0, 0)}>
						<i class="ri-arrow-up-line text-white fs-4"></i>
					</a>{" "}
				</div>
			</div>

			<section className="best_sales">
				<Container>
					<Row>
						<Col lg="12" className="text-center mb-5">
							<h2 className="section_title">Meilleures Ventes</h2>
						</Col>
						{/* <Hero /> */}
						{
							loading ? (
								<div className="loader d-flex justify-content-center align-items-center ">
									{" "}
									<l-tailspin
										size="40"
										stroke="5"
										speed="0.9"
										color="black"
									></l-tailspin>
								</div>)
								:
								<ProductsList data={bestSalesProducts} />
						}
					</Row>
				</Container>
			</section>

			<section className="trending_products">
				<Container>
					<Row>
						<Col lg="12" className="text-center mb-5">
							<h2 className="section_title">Produits à la Mode</h2>
						</Col>
						<ProductsList data={trendingProducts} />
					</Row>
				</Container>
			</section>

			<section className="timer_count px-5">
				<Container>
					<Row>
						<div className="clock-top-content">
							<h4 className="text-white fs-6 mb-2 body_name">Offres Limitées</h4>
							<h3 className="text-white fs-5 mb-3 product_name">Qualitée Armchair</h3>
						</div>
						<Col lg="6" md="6" className="tim">
							<Clock durée={360000000} />
							<motion.button
								whileTap={{ scale: 1.2 }}
								whileHover={{ translateY: "-0.4rem" }}
								className="buy_btn store_btn product_name"
								onClick={() => navigate("/shop")}
							>
								Acquérir
							</motion.button>
						</Col>
						<Col lg="6" md="6" className="text-end">
							<img src={counterImg} alt="counterImg" />
						</Col>
					</Row>
				</Container>
			</section>

			<section className="new_arrirals">
				<Container>
					<Row>
						<Col lg="12" className="text-center mb-5">
							<h2 className="section_title">Nouveaux Produits</h2>
						</Col>
						{
							loading ? (
								<div className="loader d-flex justify-content-center align-items-center ">
									{" "}
									<l-tailspin
										size="40"
										stroke="5"
										speed="0.9"
										color="black"
									></l-tailspin>
								</div>)
								:
								(
									<>
										<ProductsList data={mobileProducts} />
										<ProductsList data={wirelessProducts} />
									</>
								)
						}
					</Row>
				</Container>
			</section>

			<section className="popular_category">
				<Container>
					<Row>
						<Col lg="12" className="text-center mb-5">
							<h2 className="section_title">Categories Populaires</h2>
						</Col>
						<ProductsList data={popularProducts} />
					</Row>
				</Container>
			</section>
		</Helmet>
	);
}

export default Home