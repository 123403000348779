import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
// import products from "../../assets/data/products";
import Helmet from "../../components/helmet/Helmet";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { cartActions } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { tailspin } from "ldrs";
import "./product_detail.css";
import ProductsList from "../../components/UI/producList/ProductList";
import CommonSection from "../../components/UI/commonSection/CommonSection";
import { getData } from "../../components/UI/utils/utilfunction";
const ProductDetails = () => {
	const { id } = useParams();
	// const produit = products.find((item) => item.id == id);
	const [product, setProduct] = useState({})
	const [tab, setTab] = useState("desc");
	const [rating, setRating] = useState("");
	const [showImg, setShowImg] = useState()
	const [quantity, setQuantity] = useState(1)
	const [Allproduct, setAllProduct] = useState([])
	const dispatch = useDispatch();
	const [imageTodisplay, setImageToDisplay] = useState(0)
	tailspin.register();
	// const [state, setState] = (0)
	const reviewUser = useRef("");
	const reviewMsg = useRef("");
	const formRef = useRef("");
	const url = process.env.REACT_APP_API_URL

	const handleImg = (e, index) => {
		// setSecondImg(e.target.currentSrc)
		let uno = showImg.shift()
		let sec = showImg[index].shift()
		showImg.push(uno)
		showImg.unshift(sec)

		console.log("imaginaire", e.target.currentSrc)
		console.log("imaginaire", showImg)
	}
	const changeImage = (imgIndex) => setImageToDisplay(imgIndex)


	const handlerSubmit = (e) => {
		e.preventDefault();

		const reviewUserName = reviewUser.current.valueOf;
		const reviewUserMsg = reviewMsg.current.valueOf;

		const reviewObj = {
			userName: reviewUserName,
			text: reviewMsg,
			rating,
		};
		toast.success("Avis soumit");
		formRef.current.reset();
	};

	const addToCart = () => {
		dispatch(
			cartActions.addItem({
				id,
				images: images,
				nom,
				prix: prix,
				quantite: quantity,
				totalPrix: prix * quantity
			})
		);

		toast.success("produit ajouté avec succèss");
	};
	const addQuantity = () => setQuantity(quantity + 1)
	const deleteQuantity = () => setQuantity(quantity - 1)

	// funtion to fetchdata
	const fetchdata = (value) => {
		fetch(value)
			.then(resp => resp.json())
			.then(data => {
				// setFilterData(item)
				console.log(data, "les data du serveur")
				const filterproduit = data.data.filter((item) => item.id == id)
				setAllProduct(data.data)
				setProduct(filterproduit[0])
				setShowImg(filterproduit[0].images)
			})
	}

	const {
		nom,
		prix,
		images,
		titre,
		quantite,
		note,
		reviews,
		description,
		categorie,
	} = product
	// console.log("la categorie", categorie)
	const relatedProducts = Allproduct.filter((item) => item.categorie.Nom == categorie.Nom);
	useEffect(() => {
		window.scrollTo(0, 0);
		setShowImg()

		// fetchdata("https://63.250.59.10:8055/items/Articles?fields=*,categorie.Nom,images.directus_files_id")

		getData(
			"https://api.marsha-boutique.com/items/Articles?fields=*,categorie.Nom, images.directus_files_id"
		).then((item) => {
			const filterproduit = item.data.filter((element) => element.id == id)
			setAllProduct(item.data)
			setProduct(filterproduit[0])
			setShowImg(filterproduit[0].images)
		});

	}, [id])

	// console.log("les  produits", Allproduct)
	// console.log("id", id)
	// console.log("les images", showImg)
	return (
		<Helmet title={nom}>
			<CommonSection title={nom} />
			<section>
				<Container className="product_detail">
					<Row>
						<Col md="4" sm="12">
							<Col lg="12">
								{
									// <img src={"http://63.250.59.10:8055/assets/433acd35-2525-42de-8ef8-5b8672bf6065"} alt="" className="mainImg" />
									// <img src={showImg ? `http://63.250.59.10:8055/assets/${showImg[0].directus_files_id}` : " "} alt={nom} className="mainImg" />
									<img src={product.images && product.images.length ? `https://api.marsha-boutique.com/assets/${product.images[imageTodisplay].directus_files_id}` : ""} alt={nom} className="mainImg" />
								}
							</Col>
							<Col>
								<div className="all_img d-flex">
									{
										showImg ? showImg.map((item, index) => (
											<img
												key={index}
												src={`https://api.marsha-boutique.com/assets/${showImg[index].directus_files_id}`}
												alt={nom}
												className="slide mt-2 px-1"
												// onClick={(e) => handleImg(e, index)}
												onClick={() => setImageToDisplay(index)}
											/>
										)) : ""
									}
								</div>
							</Col>
						</Col>
						<Col md="5" sm="12">
							<div className="product_details">
								<h1 className="product_name">{nom}</h1>
								<div className="product_rating d-flex align-items-center gap-5 mb-3">
									<div>
										<span>
											<i className="ri-star-s-fill"></i>
										</span>
										<span>
											<i className="ri-star-s-fill"></i>
										</span>
										<span>
											<i className="ri-star-s-fill"></i>
										</span>
										<span>
											<i className="ri-star-s-fill"></i>
										</span>
										<span>
											<i className="ri-star-s-fill"></i>
										</span>
									</div>
									<p>({note} ratings)</p>
								</div>
								<div className=" align-items-center gap-5">
									<span className="product_price">{prix} Xaf</span>
									<br />
									<br />
									{/* <span className="category_product">Categorie: {categorie.Nom.toUpperCase()}</span> */}
								</div>
								<p className="mt-3 category_product">{description}</p>
								<div className="quantity">
									<span onClick={deleteQuantity}> - </span>
									<span> {quantity} </span>
									<span onClick={addQuantity}> + </span>
								</div>
								<motion.button
									whileTap={{ scale: 1.2 }}
									className="btn_detail "
									onClick={addToCart}
								>
									Add to Cart
								</motion.button>
							</div>
						</Col>
						<Col md="3">
							<h4 className="title_descript">DISTRIBUTION DE PRODUITS</h4>
							<div className="mt-3 ">
								<h6 className="title_descript">Paiement sécurisé</h6>
								<p className="text_descript">
									Naviguez en toute sécurité et effectuez un paiement sécurisé.
								</p>
							</div>
							<div className="mt-3 ">
								<h6>norme et qualité</h6>
								<p className="text_descript">Ce produit est agrée par la norme iso 9001.</p>
							</div>
							<div className="mt-3 ">
								<h6 className="title_descript">Livraison rapide</h6>
								<p className="text_descript">Option de livraison rapide disponible sur ce produit.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col lg="12">
							<div className="tab_wrapper d-flex align-items-center gap-5">
								<h6
									className={`${tab == "desc" ? "active_tab" : ""}`}
									onClick={() => setTab("desc")}
									title="Description"
								>
									Description
								</h6>
								<h6
									className={`${tab == "rev" ? "active_tab" : ""}`}
									onClick={() => setTab("rev")}
									title="Reviews"
								>
									{/* Reviews({note?})<i class="ri-arrow-down-s-line"></i> */}
								</h6>
							</div>
							{tab == "desc" ? (
								<div className="tab_content mt-5">
									<p>{description}</p>
								</div>
							) : (
								<div className="product_review">
									<div className="review_wrapper">
										<ul className="mt-3">
											{reviews.map((item, index) => (
												<li key={index}>
													<span>{item.rating} (rating)</span>
													<p>{item.text}</p>
												</li>
											))}
										</ul>
										<div className="review_form">
											<h4>Laisser Votre Avis</h4>
											<form
												action=""
												id="form"
												ref={formRef}
												onSubmit={handlerSubmit}
											>
												<div className="form_group">
													<input
														type="text"
														placeholder="text here"
														ref={reviewUser}
														required
													/>
												</div>
												<div className="form_group rating_group d-flex align-items-center gap-5">
													<motion.span
														whileTap={{ scale: 1.2 }}
														onClick={() => setRating(1)}
													>
														1<i className="ri-star-s-fill"></i>
													</motion.span>
													<motion.span
														whileTap={{ scale: 1.2 }}
														onClick={() => setRating(2)}
													>
														1<i className="ri-star-s-fill"></i>
													</motion.span>
													<motion.span
														whileTap={{ scale: 1.2 }}
														onClick={() => setRating(3)}
													>
														1<i className="ri-star-s-fill"></i>
													</motion.span>
													<motion.span
														whileTap={{ scale: 1.2 }}
														onClick={() => setRating(4)}
													>
														1<i className="ri-star-s-fill"></i>
													</motion.span>
													<motion.span
														whileTap={{ scale: 1.2 }}
														onClick={() => setRating(5)}
													>
														1<i className="ri-star-s-fill"></i>
													</motion.span>
												</div>
												<div className="form_group">
													<textarea
														ref={reviewMsg}
														rows={4}
														type="text"
														placeholder="Review Message..."
														required
													/>
												</div>
												<motion.button
													whileTap={{ scale: 1.2 }}
													className="btn_detail"
												>
													envoyer
												</motion.button>
											</form>
										</div>
									</div>
								</div>
							)}
						</Col>
					</Row>
				</Container>
				<Container className="mt-3">
					<Row>
						<Col lg="12" className="my-3">
							<h2 className="related_title">Vous pourriez aussi aimer</h2>
						</Col>
						<ProductsList data={relatedProducts.slice(0, 8)} />
					</Row>
				</Container>
			</section>
		</Helmet>
	);
};
export default ProductDetails;