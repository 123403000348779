import  { configureStore } from '@reduxjs/toolkit'
import cartSlice from './slices/cartSlice'
import factureSlice from './slices/factureSlice'
import productSlice from "./slices/productSlice"

const store = configureStore({
    reducer: {
        cart: cartSlice,
        facture: factureSlice,
        produit: productSlice,
    }
})  

export default store 