import React, { useEffect } from 'react'
import { Col } from 'reactstrap';
// import { MdLocationPin } from 'react-icons/md' 
// import { BsTelephoneFill } from 'react-icons/bs' 
// import { HiMail } from 'react-icons/hi' 
// import img1 from './call-center.jpg'
// import Mape from './Map.jsx'


import './contact.css'

function Contact() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  

    return (
      <div className="div help pt-5 ">
        <div className="bg_contact">
          <div className="breathcum_title">
            <h1>Nous Contacter</h1>
          </div>
        </div>
        <section id="contact" className="contact ">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <p>
                Pour nous contacter veillez vous rendre a bonapriso derrière la
                pharmacie du plateau ou part téléphone oubien par message
                électronique. Merci pour votre fidelite Marsha boutique les
                produits idéales.
              </p>
            </div>

            <div className="row d-flex  mt-5" data-aos="fade-up">
              <div className="">
                <div className="d-md-flex justify-content-around align-items-center  text-dark ">
                  <Col className="my-4 curseur cont ">
                    <div
                      className="service_item"
                      // style={{ background: `${item.bg}` }}
                    >
                      <span>
                        <i className="bi bi-phone"></i>
                      </span>
                      <div>
                        <h3 className="home_service_title">Location:</h3>
                        <p className="body_service_title">
                          Bonapriso, derrière la pharmacie du plateau
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="my-4 curseur cont">
                    <div
                      className="service_item"
                      // style={{ background: `${item.bg}` }}
                    >
                      <span>
                        <i className="bi bi-phone"></i>
                      </span>
                      <div>
                        <h3 className="home_service_title">Email:</h3>
                        <p className="body_service_title">Exemple@gmail.com</p>
                      </div>
                    </div>
                  </Col>
                  <Col className="my-4 curseur cont">
                    <div
                      className="service_item"
                      // style={{ background: `${item.bg}` }}
                    >
                      <span>
                        <i className="bi bi-phone"></i>
                      </span>
                      <div>
                        <h3 className="home_service_title">Call:</h3>
                        <p className="body_service_title">+237 651 87 02 40</p>
                      </div>
                    </div>
                   
                  </Col>
                </div>
              </div>

              <div className="col-lg-5 ">
                <p>Map loading ...</p>
                {/* <iframe style={{border:'0',width: '100%',height: '270px'}} src={www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621 } frameborder={0} allowfullscreen /> */}
              </div>
            </div>

            <div className="row mt-5 justify-content-center" data-aos="fade-up">
              <div className="col-lg-10">
                <form
                  action="#"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Contact
