const french = {
  translation: {
    // navigation bar
    link1:  "Acceuil",
    link2:  "Boutique",
    link3: "Services",
    link4:  "Panier",
    link5:  "Contact",
    title: "Boutique",

    //services
    service1: "Livraison Facile",
    service1: "Livraison Facile",
    service1: "Livraison Facile",
    service1: "Livraison Facile",

    //searchBar
    search: "Trouver un Produit...",
    searchsell: "Vendez vos produits",
    titre: "Boutiques",
    logout: "Deconnexion",
    headerSubtitle1: "Acheter un produit",
    headerSubtitle2: "vendre un produit",
    headerSubtitle3: "se connecter",
    headerSubtitle4: "Administrateur",

    //banner for home
    banner1: "Produit en vogue",
    banner2: "Achetter les Produits qui vous conviennent dans votre boutique",
    banner3: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium sed aliquam hic enim repellendus porroquisquam in eius, delectus iste mollitia officia perferendis fugit libero corporis quia culpa ratione animi.",
    banner4: "Acheter",

    

  }
}

export default french
