import React,{useEffect, useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import Helmet from '../../components/helmet/Helmet'
import CommonSection from '../../components/UI/commonSection/CommonSection'
import {Container, Row, Col} from 'reactstrap'
import { motion } from 'framer-motion'
import { factureActions } from '../../redux/slices/factureSlice'
import products from '../../assets/data/products'
import { useSelector, useDispatch } from 'react-redux'
import Bill from './bill/Bill'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import logo from "../../assets/images/logo-marsha.png"
import { useTranslation } from 'react-i18next'
// import { PDFViewer } from '@react-pdf/renderer';

import './facture.css'

const Facture = () => {

  const [showbill, setShowbill] = useState(false)
  const toggle = () => setShowbill(!showbill);
  const navigate = useNavigate() 
  const {t} = useTranslation()

  const factureItems = useSelector(state => state.facture.factureItems)
  const totalAmount = useSelector(state => state.facture.totalAmount)
  const totalQuantite = useSelector(state => state.facture.totalquantite)
  const store = useSelector(state => state.facture)
  console.log("le store",store)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // console.log("les elements de facture", factureItems)
  return (
    <Helmet title="panier des produits">
      {/* <CommonSection title="facture de shopping" /> */}
      <div className="facture noimprime">
        <div className="lia d-flex justify-content-around pt-4 ">
          <div>
            <Link to="/">
              <h2>
                <span className="tec">Marsha</span>
                {t("title")}
              </h2>
            </Link>
            {/* <p>since 2022</p> */}
          </div>
          <div className="imag">
            <img className="log_marsha" src={logo} alt="loo marsha" />
          </div>
        </div>

        <section className='py-0'>
          <Container>
            <Row>
              <Col sm="12" lg="9" className='pt-5'>
                {factureItems.length == 0 ? (
                  <h2 className="fs-4 text-center">
                    Aucun élément ajouté à votre Facture
                  </h2>
                ) : (
                  <table className="table bordered">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Titre</th>
                        <th>Prix</th>
                        <th>Qty</th>
                        <th>Supprimer</th>
                      </tr>
                    </thead>

                    <tbody>
                      {factureItems.map((item, index) => (
                        <LigneItem item={item} index={index} />
                      ))}
                    </tbody>
                  </table>
                )}
              </Col>

              <Col sm="12" lg="3" className='detail_fact'>
                <div className="mt-5 pt-1 pt-md-4">
                  <div className='pb-md-5 pb-2 text-center'>Prix Total:
                    <span className='fw-bold fs-5'> {totalAmount} Xaf </span>
                  </div>
                  <h6 className="fw-bold d-flex justify-content-center">
                    Verification Partiel
                    {/* <span className="fs-5 fw-bold">{totalAmount} Xaf</span> */}
                  </h6>
                  <p className="fs-6 mt-3 text-center">
                    Verifier le nombre de chaques produits avant de passer à la
                    validation de la commande.
                  </p>
                  <div>
                    <button
                      className={
                        factureItems.length == 0
                          ? "btn_detail w-100 mt-5 disabled"
                          : "btn_detail w-100 mt-5"
                      }
                      onClick={toggle}
                    >
                      Facturer Commande
                    </button>
                    {/* </Link> */}
                    {/* <Link to="/shop">
                      <button className="btn_detail w-100 mt-3">
                        Continuer Ajout
                      </button>
                    </Link> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className=" btn_retour">
            <span onClick={() => navigate("/vendre")}>
              <i class="ri-arrow-left-fill"></i> Retour
            </span>
          </div>
        </section>
        <Modal isOpen={showbill} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <div className='ms-3'>FACTURE</div>
          </ModalHeader>
          <ModalBody>
            {/* <PDFViewer> */}
            <Bill />
            {/* </PDFViewer> */}
          </ModalBody>
        </Modal>
      </div>
    </Helmet>
  );
} 



const LigneItem = ({item, index}) => {

  // const { imgUrl, productName, price, avgRating, reviews, description, shortDesc, category} = produit
  // const produit = products.find(item => item.id == id)
  const dispatch = useDispatch()

  //function to delete product
  const deleteProduct = ()=>{
    dispatch(factureActions.deleteItem(item.id))
  }
  const removeProduct = ()=> {
    dispatch(factureActions.removeItem(item.id))
  }
  //function to add number of product
  const addNumberProduct = ()=>{
      dispatch(
        factureActions.addItem({
          id: item.id,
          images: item.images,
          nom: item.nom,
          prix: item.prix,
          quantite: 1,
          totalPrix: item.prix
        })
      ); 
  }

  const totalAmount = useSelector(state => state.facture.totalAmount)

  return (
    <tr key={index}>
      <td>
      {/* <Link to={`/productDetails/${item.id}`}> */}
        <img 
          src={item.images == 0 ?  "" : `http://63.250.59.10:8055/assets/${item.images[0].directus_files_id}`}
          alt="article1" 
        />
      {/* </Link> */}
      </td>
      <td >{item.nom}</td>
      <td>{item.prix} Xaf</td>
      <td>{item.quantite} pcs</td>
      <td>
        <span className='cart_others'>
          <motion.i
            whileTap={{ scale: 1.3 }}
            onClick={removeProduct}
            class="ri-delete-bin-line"
          ></motion.i>
          <span className="cart_add_item">
            <span className="cart_left" onClick={deleteProduct}>
              -
            </span>
            1{" "}
            <span className="cart_rigth" onClick={addNumberProduct}>
              +
            </span>
          </span>
        </span>
      </td>
    </tr>
  );
}

export default Facture