import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import './footer.css'
import logo from '../../assets/image/logo-marsha.png'


const Footer = () => {

  const year = new Date().getFullYear()
  return (
    <footer className="footer noimprime">
      <Container>
        <Row>
          <Col lg="4" md="6" cl>
            <div className="logo_footer">
              <Link to="/">
                <div>
                  <h5 className="text-white blig">MarshaBoutique</h5>
                </div>
                <img className="me-1" src={logo} alt="logo" />

              </Link>
            </div>
            <p className="footer_text mt-4">
              Marsha Boutique & Beauty Salon Votre Espace Coiffure et ses 1000
              et 1 Senieurs!
            </p>
            <Col>
              <h6 className="text-white blig mt-xs-2 mt-lg-4">
                Heures D'ouvertures
              </h6>
              <p className="mt-2">Lundi à samedi 08:00 - 20:00</p>
            </Col>
          </Col>

          <Col lg="3" md="3">
            <div className="footer_quick-links">
              <h5 className="quick_links-title">Meilleures Categories</h5>
              <ListGroup className="mt-2">
                <ListGroupItem className="ps-0 border-0">
                  <Link to="#">Diffuseur</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="#">Téléphone portable</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="#">Sac à main</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="#">Montres de luxes</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="2" md="3">
            <div className="footer_quick-links">
              <h5 className="quick_links-title">Liens Utiles</h5>
              <ListGroup className="mt-2">
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop">Shop</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/cart">Cart</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/login">Login</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="#">Privacy Policy</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4">
            <div className="footer_quick-links">
              <h5 className="quick_links-title">Contact</h5>
              <ListGroup className="footer_contact mt-2">
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-map-pin-line"></i>
                  </span>
                  <p>Bonapriso, derrière la pharmacie du plateau</p>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-phone-line"></i>
                  </span>
                  <a
                    href="https://api.whatsapp.com/send?phone=237651870240"
                    target="_blank"
                  >
                    <p>+237 651 87 02 40</p>
                  </a>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-mail-line"></i>
                  </span>
                  <a href="exemple@gmail.com">
                    <p>exemple@gmail.com</p>
                  </a>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="12" className="md-mt-5">
            <p className="footer_copyright">
              Copyright {year} developper par{" "}
              <a
                className="dev_link"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=237678719650"
              >
                Stallone William
              </a>
              . Tout droits reservés MarshaBoutique.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}                   

export default Footer