const english = {
  translation: {
    // navigation barre
    link1: "Home",
    link2: "Shop",
    link3: "Sercices",
    link4:  "Cart",
    link5:  "Contact",
    title: "Shop",

    //services
    service1: "Livraison Facile",
    service1: "Livraison Facile",
    service1: "Livraison Facile",
    service1: "Livraison Facile",

    //searchBar
    search: "Find a Product...",
    searchsell: "Selling your products",
    titre: "Shops",
    logout: "Logout",
    headerSubtitle1: "Buy a product",
    headerSubtitle2: "sell a product",
    headerSubtitle3: "Login",
    headerSubtitle4: "Administrator",

     //banner for home
     banner1: "Product in vogue",
     banner2: "Buy the right products in your store",
     banner3: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium sed aliquam hic enim repellendus porroquisquam in eius, delectus iste mollitia officia perferendis fugit libero corporis quia culpa ratione animi.", 
     banner4: "Buy",

    }
}

export default english
