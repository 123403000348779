import React from "react";
import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,A11y, EffectFade, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay'
import "swiper/css/pagination";
import "swiper/css/effect-fade";


import "./hero.css";
import heroImg from "../../assets/images/hero-img.png";
import imgDivider from "../../assets/images/divider.svg"

const data = [{
  title: ""
}]
const Hero  = () => {
    const year = new Date().getFullYear();
    return (
      <section className="hero">
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          loop={true}
          autoplay={{ delay: 2000 }}
          modules={[Pagination,Autoplay, A11y]}
          // slidesPerView={0}
          className="mySwiper"
        >
          <SwiperSlide>
            <section className="hero_section mb-1 ">
              <Container>
                <Row>
                  
                </Row>
              </Container>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            
          </SwiperSlide>
          <SwiperSlide>
            
          </SwiperSlide>
          {/* <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide> */}
          {/* <SwiperSlide>Slide 5</SwiperSlide>
          <SwiperSlide>Slide 6</SwiperSlide>
          <SwiperSlide>Slide 7</SwiperSlide>
          <SwiperSlide>Slide 8</SwiperSlide>
          <SwiperSlide>Slide 9</SwiperSlide> */}
        </Swiper>
      </section>
    );
  };
  
  export default Hero;
 