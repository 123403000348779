import React, { useEffect } from 'react'
import {Container,Row, Col } from 'reactstrap'
import {motion} from 'framer-motion'

import Section4 from './section4/Section4'
import './services.css'
import Hero from '../../components/hero/Hero'

const Services = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <section className="services">
      
      <div className="bg_service">
        <div className="breathcum_title">
          <h1 className='text-white'>Nos Services</h1>
        </div>
      </div>
      <Container>
				<Col lg="12" className="text-center">
					<h2 className="section_tit mt-5 pt-5">Zone de Relaxation</h2>
					<h4 className='section_title1 mt-3'>L'accès au centre de remise en Beauté est  pour tous les clients de la boutique. Vos prosduits fares et vos multiples services sont continuements disponibles chez nous pour votre bien etre.</h4>
				</Col>
			</Container>
			<Hero />
      <Col className="telecom">
        <Section4 />
      </Col>
    </section>
  );
}

export default Services