import React from 'react'

import './section4.css'
import Title from '../title/Title'
import makup from '../../../assets/images/hero-bg3.png'
import parfum from '../../../assets/images/hero-img3.png'
import meche from '../../../assets/images/gal-1-4.jpg'
import flora from '../../../assets/images/flora.png'

function Section4() {
    return (
        <div className="section4">
            <div className="wrapper">
                <Title text="Nos differents services" />
                <p>
                   Nous vous offrons des services de qualites suprieures
                </p>
                <div className="blocks">
                    <Block title="Distribution des parfums pour maison" icon={parfum} />
                    <Block title="Disribution des senteurs unique et agreable" icon={flora} />
                    <Block title="Distribution meches d'origines" icon={meche} />
                    <Block title="Coiffures manicures" icon={makup} />
                </div>
            </div>
        </div>
    )
}

const Block = ({title, icon}) => {
    return (
        <div className="block">
            <div className="icon">
                <img src={icon} alt="" />
            </div>
            <div className="text">
                <h3 className='text-orange'>{title}</h3>
                <p>
                    Voluptatum deleniti atque corrupti quos
                    dolores et quas molestias excepturi sint occaecati cupiditate non provident
                </p>
            </div>
        </div>
    )
}

export default Section4