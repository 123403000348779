import React, { useState } from 'react'
import { Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import "./bill.css"

const Bill = () => {

  const [article, setArticle] = useState({})
  const factureItems = useSelector(state => state.facture.factureItems)
  const totalAmount = useSelector(state => state.facture.totalAmount)
  const dispatch = useDispatch()

  const dataAricle = factureItems.map((item, index) => ({ "article": item.id, "quantite": item.quantite }))

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'User-Agent': 'insomnia/8.4.5',
      Authorization: 'Bearer GqGaW1XDzpCJzn06R7U-iWvtMOX8XOlP'
    },
    body: `{"command": ${JSON.stringify(dataAricle)}}`
  };
  // '{"command": dataAricle}'

  //funcion to buy product to api
  const handleBuy = () => {
    fetch('https://api.marsha-boutique.com/directus/sell', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }

  const handlePrint = () => {
    window.print()
    handleBuy()
  }

  return (
    <>
       <div className="bill">
            <div className="fs-3 text-center text-black py-0 my-0 fw-bold ">Marsha Boutique</div>
            <div className='text-center text-black fw-bold py-0 my-0 fs-6'>Bonapriso, derrière la pharmacie du plateau.</div>
                   <div className='text-center text-black fw-bold py-0 my-0 fs-6'> Tel: +237 651 87 02 40</div>
                  <div className="text-center text-black fw-bold py-0 my-0 fs-6">Date : 19 / 05 / 2023</div> 
                  
            <div className="tableau d-flex mt-4 fw-bold fs-6">
              {/* <div className='mx-2'>Identifiant</div> */}
              <div className='ms-3 fw-bold me-4'>designation</div>
              <div className='mx-4 fw-bold'>Prix</div>
              <div className='mx-3 fw-bold'>Quantite</div>
              <div className='mx-4 fw-bold'>Sous-Total</div>
            </div>

            {
              factureItems.map((item, index) => (
                <div className="tableau d-flex  fw-bold fs-6">
                  {/* <div className='mx-2 texts'>{item.id}</div> */}
                  <div className='mx-2 fw-bold texti1'>{item.nom}</div>
                  <div className='mx-2 fw-bold texti'>{item.prix}</div>
                  <div className='text-center fw-bold'>{item.quantite}</div>
                  <div className='mx-2 fw-bold texti text-end'>{item.totalPrix * item.quantite} Xaf</div>
                </div>
              ))
            }
        <div className="d-flex my-3 d-flex justify-content-end ">
          <div className="table_one w-25 text-black fw-bold">Net à Payer</div>
          <div className="table_two w-25 text-black fw-bold ">{totalAmount} Xaf</div>
        </div>
        <p className="texto text-black ">
             Tous les articles achetés sont ni repris, ni echangeable!
            </p>
      </div>
      <span className="btn_imprimer " onClick={handlePrint}>
        Imprimer
      </span> 
    </>
  );
}

export default Bill

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );
