import React,{useState, useEffect} from 'react'
import Helmet from '../../components/helmet/Helmet'
import CommonSection from '../../components/UI/commonSection/CommonSection'
import { Container,Row,Col } from 'reactstrap'
import { useSelector } from 'react-redux'
// import ProductsList from '../../components/UI/producList/ProductList'
import ShoppingList from '../../components/UI/shoppingList/ShoppingList'

import "./shop.css"

const Shop = () => {

  const products = useSelector(state => state.produit.ProductsItems)
  const [productData, setProductData] = useState(products)
  const [selected, setSelected] = useState('')
  const [searchActiver,setSerchActiver] = useState(false)
  
  useEffect(()=>{
    // setProductData(products)
    window.scrollTo(0, 0);
  },[])

  const handleChoice = e => {
    setSelected(e.target.value)
    const filterProductData = products.data.filter((item)=>item.categorie.Nom === e.target.value)
    // item.data.filter(
    //   element => element.categorie.Nom == "parfum"
    // )

    setProductData(filterProductData)
    if (e.target.value == "all") {
      setSerchActiver(false)
    }
    setSerchActiver(true)
  }
  // console.log(productData)
  const handleSearch = e =>{
    let searchTerm = e.target.value
    const searchedProduct = products.filter(item => item.productName.toLowerCase().includes(searchTerm.toLowerCase())) 
    setProductData(searchedProduct)
  }
  console.log("les produits shop", products)
  return (
    <Helmet title="boutique">
      <CommonSection title="Produits" />

      <section>
        <Container>
          <Row>
            <Col lg="3" md="6" className='text-end'>
              <div className="filter_widget">
                <select onChange={handleChoice}>
                  <option defaultValue="all">Filtrer Par Categories</option>
                  <option value="diffuseur">diffuseur</option>
                  <option value="freshner">freshner</option>
                  <option value="parfum">parfum</option>
                  <option value="bougie">bougie</option>
                  <option value="marsha">marsha</option>
                </select>
              </div>
            </Col>
            <Col lg="3" md="6">
              <div className="filter_widget">
                <select>
                  <option>Filtrer Par Ordre </option>
                  <option value="Sofa">croissant</option>
                  <option value="mobile">Decroissant</option>
                </select>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="search_box">
                <input onChange={handleSearch} type="text" placeholder="Recherche...." />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="new_arrirals">
        <Container>
          <Row>
            {
              productData.length == 0 ? <h2>Aucun Produits Disponibles</h2> : (
                <>
                  <Col lg="12" className="text-center mb-5">
                    <h2 className="section_title">Categorie: {selected} </h2>
                  </Col>
                  <ShoppingList data={products[0].data} />
                </>
              )
            }
          </Row>
        </Container>
      </section>
    </Helmet>
  );
}

export default Shop