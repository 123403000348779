import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    cartItems: [],
    totalAmount: 0,
    totalQuantite: 0
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem:(state, action)=>{
        const newItem = action.payload;
        const existingItem = state.cartItems.find((item)=>item.id === newItem.id)
        console.log("le newItem",newItem)
        
        // state.totalQuantite++

        if(!existingItem){
            state.cartItems.push({
                id: newItem.id,
                nom: newItem.nom,
                images: newItem.images,
                prix: newItem.prix,     
                quantite: newItem.quantite,
                totalPrix: newItem.prix 
            })
        } 
        else{
            existingItem.quantite = existingItem.quantite + newItem.quantite
            existingItem.totalPrix = Number(existingItem.totalPrix) + Number(newItem.totalPrix)
        }

        state.totalAmount = state.cartItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)
        state.totalQuantite = state.cartItems.reduce((quantite,item)=> quantite + Number(item.quantite),0)
    },

    deleteItem: (state, action)=> {
        const id = action.payload
        const existingItem = state.cartItems.find(item => item.id == id )

        if (existingItem.quantite == 1 || existingItem.quantite == 0) {
            state.cartItems = state.cartItems.filter( item => item.id != id)
        } else {
            existingItem.quantite--
        }
        
        state.totalQuantite = state.totalQuantite - 1
        state.totalAmount = state.cartItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)
    },

    removeItem: (state, action) =>{
        const id = action.payload
        const existingItem = state.cartItems.find(item => item.id == id )

        if (existingItem) {
            state.cartItems = state.cartItems.filter( item => item.id != id)
        }
        
        state.totalQuantite = state.totalQuantite - existingItem.quantite
        state.totalAmount = state.cartItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)

    }
  }
});

export const cartActions = cartSlice.actions

export default cartSlice.reducer