import React from 'react'

const AllProducts = () => {
  return (
    <div>
      <h1>all product Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime minima aliquid quam amet molestias, illum, possimus dolor veniam adipisci accusantium reprehenderit sed voluptates? Laboriosam quaerat perferendis corrupti accusamus. Vero, quasi.</h1>
    </div>
  )
}

export default AllProducts