import React from 'react'
import ProductCard from '../productCard/ProductCard'

const ProductsList = ({data}) => {
  return (
    <>   
    {
      data?.map((item,index) =>(
        <ProductCard item={item} key={index} />
      ))
    }
    </>
  )
}

export default ProductsList