import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    factureItems: [],
    totalAmount: 0,
    totalquantite: 0
}

const factureSlice = createSlice({
    name : 'facture',
    initialState,
    reducers: {
        addItem:(state, action)=>{
        const newItem = action.payload;
        const existingItem = state.factureItems.find((item)=>item.id === newItem.id)
        console.log("le newItem",newItem)
        
        // state.totalquantite++

        if(!existingItem){
            state.factureItems.push({
                id: newItem.id,
                nom: newItem.nom,
                images: newItem.images,
                prix: newItem.prix, 
                quantite: newItem.quantite,
                totalPrix: newItem.prix 
            })
        } 
        else{
            existingItem.quantite = existingItem.quantite + newItem.quantite
            existingItem.totalPrix = Number(existingItem.totalPrix) + Number(newItem.totalPrix)
        }

        state.totalAmount = state.factureItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)
        state.totalquantite = state.factureItems.reduce((quantite,item)=> quantite + Number(item.quantite),0)
        },

        deleteItem: (state, action)=> {
            const id = action.payload
        const existingItem = state.factureItems.find(item => item.id == id )

        if (existingItem.quantite == 1 || existingItem.quantite == 0) {
            state.factureItems = state.factureItems.filter( item => item.id != id)
        } else {
            existingItem.quantite--
            existingItem.totalPrix = existingItem.totalPrix - existingItem.prix
        }
        
        state.totalquantite = state.totalquantite - 1
        state.totalAmount = state.factureItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)
        },
        removeItem: (state, action) =>{
            const id = action.payload
        const existingItem = state.factureItems.find(item => item.id == id )

        if (existingItem) {
            state.factureItems = state.factureItems.filter( item => item.id != id)
        }
        
        state.totalquantite = state.totalquantite - existingItem.quantite
        state.totalAmount = state.factureItems.reduce((total, item)=> total + Number(item.prix) * Number(item.quantite),0)
        },
    }
})

export const factureActions = factureSlice.actions

export default factureSlice.reducer