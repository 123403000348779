import React from 'react'

const AddProducts = () => {
  return (
    <div>
      <h1>AddProducts Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde laudantium nisi minus aperiam tempore natus, cumque consectetur dolores praesentium nobis perferendis harum veritatis! Earum ab placeat velit esse ea? Pariatur.</h1>
    </div>
  )
}

export default AddProducts